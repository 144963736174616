// import logo from './logo.svg';
import "./App.css";
import Navigation from "./Components/Header/Navigation";
import AboutMe from "./Components/Body/AboutMe";
import Skills from "./Components/Body/Skills";
import Project from "./Components/Projects/Project";
import pokeapi from './pokeapi.jpeg'

function App() {
  const aboutMeText =
    "Hi, I'm Greg, a Computer Science and Engineering major at UCI interested in backend development, algorithms, and blockchain technology. In my free time, I love rock climbing, mountain biking, and skiing.";

  const projectContents = [
    {
      title: "Python API Project",
      desc: "Using the python standard library and pokeapi.co I created an algorithm that calculates the strongest counters given a specific pokemon.",
      source: pokeapi
    },
  ];

  const allProjects = projectContents.map((contents) => (
    <Project
      title={contents.title}
      key={contents.title}
      desc={contents.desc}
      source={contents.source}
    />
  ));

  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
      </header>
      <AboutMe text={aboutMeText} id="aboutme" />
      <div id="skills-div">
        <Skills />
      </div>
      <div id="projects">{allProjects}</div>
    </div>
  );
}

export default App;
