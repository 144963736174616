import "./Navigation.css";
import NavigationTitle from "./NavigationTitle";
import NavigationItems from "./NavigationItems";


const Navigation = () => {
  const navigationItems = [
    { name: "Bio", link: "#aboutme" },
    { name: "Skills", link: "#skills-div" },
    { name: "Projects", link: "#projects" },
  ];

  return (
    <div className="navigation" id='nav'>
      <NavigationTitle />
      <NavigationItems items={navigationItems} />
    </div>
  );
};

export default Navigation;
