import "./Project.css";

const Project = (props) => {
  return (
    <div className="project">
      <div className="project-contents">
        <img
          className="project-image"
          src={props.source}
          alt={props.source}
        />
        <div className="vertical-split">
          <h1 className="vertical-title">{props.title}</h1>
          <p className="project-desc">{props.desc}</p>
          <a className="project-button" href="https://github.com/gshklovs/pokemon-counters">Check it out</a>
        </div>
      </div>
    </div>
  );
};

export default Project;
