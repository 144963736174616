import "./NavigationItems.css";
import NavigationItem from "./NavigationItem";
import Pdf from './CV.pdf'

const NavigationItems = (props) => {
  const allItems = props.items.map((item) => (
    <NavigationItem name={item.name} key={item.name} itemLink={item.link} />
  ));
  return (
    <div className="navigation-items">
      {allItems}
      <a href={Pdf} className='navigation-item' without rel="noopener noreferrer" target="_blank">
        Curriculum Vitae
      </a>
    </div>
  );
};

export default NavigationItems;
