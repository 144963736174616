import "./Skills.css";
import SkillsContainer from "./SkillsContainer";

const Skills = props => {
  return (
    <div className="skills">
      <h2 className="skills__code-picture">{'</>'}</h2>
      <h1 className="skills__title">Skills</h1>
      <SkillsContainer/>
    </div>
  );
};

export default Skills;
