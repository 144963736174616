import "./SkillsContainer.css";
import pythonPhoto from "./python.png";
import reactPhoto from "./react.png";
import cPhoto from "./c++.png";

const SkillsContainer = (props) => {
  return (
    <div className="skills-container">
      <div className="skills-container__item">
        <p className="skills-container__item-name">python3</p>
        <img className="skills-container__image" src={pythonPhoto} alt="" />
      </div>
      <div className="skills-container__item">
        <p className="skills-container__item-name">React</p>
        <img className="skills-container__image" src={reactPhoto} alt="" />
      </div>
      <div className="skills-container__item">
        <p className="skills-container__item-name">C++</p>
        <img className="skills-container__image" src={cPhoto} alt="" />
      </div>
    </div>
  );
};

export default SkillsContainer;
