import "./AboutMe.css";
import smile from "./smile.png";

const AboutMe = (props) => {
  return (
    <div className="about-me">
      <h1 className="about-me__title">About Me</h1>
      <div className="about-me__items">
        <div className="about-me__item">
          <p className="about-me__text">{props.text}</p>
        </div>
        <div className="about-me__item">
          <img src={smile} className="about-me__image" />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
